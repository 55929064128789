import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import { FormikHelpers } from 'formik'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import { IEmailTemplateFormData } from './forms/EmailTemplateForm'
import EmailTemplateForm from './forms/EmailTemplateForm'
import { getEmailTemplate, updateEmailTemplate } from './utils'
import * as EmailTemplates from 'utils/email-templates';

interface IEditEmailTemplateProps extends HTMLAttributes<HTMLDivElement> {}

interface EmailTemplate {
  title: string;
  subject: string;
  body: string;
  type: string;
}

interface EmailTemplateData {
  type: string;
}

const EditEmailTemplate = ({ ...props }: IEditEmailTemplateProps) => {
  const { templateId } = useParams()
  const auth = useContext(AuthContext)
  const [template, setTemplate] = useState<EmailTemplate>({
    title: '',
    subject: '',
    body: '',
    type: '',
  });

  const fetchEmailTemplate = useCallback(async () => {
    if (auth) {
      try {
        const data: EmailTemplateData = await getEmailTemplate(String(templateId));
        updateTemplate(data);
      } catch (error) {
        console.error('Error fetching email template:', error);
        setTemplate({ title: '', subject: '', body: '', type: '' });
      }
    }
  }, [templateId, auth]);

  const updateTemplate = (data: any) => {
    // Initialize a template object
    const defaultTemplate: EmailTemplate = { title: '', subject: '', body: '', type: data?.type || '' };
    const templateMap: Record<string, string> = {
      'none': '',
      'create_job': EmailTemplates?.createJobTemplate || '',
      'forget_password': EmailTemplates?.forget_passwordTemplate || '',
      'invite_staff': EmailTemplates?.inviteStaffTemplate || '',
      'staff_access': EmailTemplates?.staffAccessTemplate || '',
      'customer_access': EmailTemplates?.customerAccessTemplate || '',
      'existing_customer_access': EmailTemplates?.existing_customer_accessTemplate || '',
      'building_job': EmailTemplates?.buildJobTemplate || '',
      'active_job': EmailTemplates?.activeJobTemplate || '',
      'payment_received': EmailTemplates?.paymentReceivedTemplate || '',
    };
  
    // Get the appropriate body based on the data type
    const body = templateMap[data?.type] !== undefined ? templateMap[data.type] : '';
  
    // Update the template based on the type
    const newTemplate: EmailTemplate = {
      title: data?.title || defaultTemplate.title, // Get title from data
      subject: data?.subject || defaultTemplate.subject, // Get subject from data
      body: body,
      type: data?.type || '',
    };
  
    // Set the new template state
    setTemplate(newTemplate);
  };

  useEffect(() => {
    fetchEmailTemplate();
  }, [fetchEmailTemplate]);

  const handleSubmit = useCallback(
    async (
      values: IEmailTemplateFormData,
      actions: FormikHelpers<IEmailTemplateFormData>
    ) => {
      console.log("valus",values);
      
      return await updateEmailTemplate(String(templateId), values,actions)
    },
    [templateId]
  )

  return (
    <div {...props} className="add-email-template templates">
      <PageHeader heading="Edit email template" showBack />
      <EmailTemplateForm handleSubmit={handleSubmit} initialValues={template} />
    </div>
  )
}

export default EditEmailTemplate
