import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { TGenericObject } from 'types/base'
import { SERVICES, TEMPLATES_TYPE, TEMPLATES_VARIABLE } from '../../../utils/constants'
import EmailPreview from '../../../components/EmailPreview/EmailPreview'
import Button from 'components/forms/Button/Button'
import TextArea from 'components/forms/TextArea/TextArea'
import TextField from 'components/forms/TextField/TextField'
import SelectField from 'components/forms/SelectField/SelectField'
import ActionModal from 'components/ActionModal/ActionModal'
import ModalPreviewAppTemplate from 'components/ActionModal/components/ModalPreviewAppTempate'
import client from 'feathers-client'
import { toast } from 'react-toastify'

import * as EmailTemplates from 'utils/email-templates';

export interface IEmailTemplateFormData {
  title: string
  subject: string
  body: string
  type: string
}

interface IEmailTemplateForm {
  handleSubmit: (
    values: IEmailTemplateFormData,
    actions: FormikHelpers<IEmailTemplateFormData>
  ) => boolean | TGenericObject
  initialValues?: any
}


const EmailTemplateForm = ({
  handleSubmit,
  initialValues,
}: IEmailTemplateForm) => {
  const [templateOpen, setTemplateOpen] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();

  type TemplateType =
    | 'none'
    | 'create_job'
    | 'forget_password'
    | 'invite_staff'
    | 'staff_access'
    | 'customer_access'
    | 'existing_customer_access'
    | 'building_job'
    | 'active_job'
    | 'payment_received';

  // State to hold the selected template type
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType>('none');

  // State for editor content
  const [editorContent, setEditorContent] = useState<string | null>(null);

  useEffect(() => {
    switch (selectedTemplate) {
      case 'none':
        setEditorContent('');
        break;
      case 'create_job':
        setEditorContent(EmailTemplates?.createJobTemplate || "");
        break;
      case 'forget_password':
        setEditorContent(EmailTemplates?.forget_passwordTemplate || "");
        break;
      case 'invite_staff':
        setEditorContent(EmailTemplates?.inviteStaffTemplate || "");
        break;
      case 'staff_access':
        setEditorContent(EmailTemplates?.staffAccessTemplate || "");
        break;
      case 'customer_access':
        setEditorContent(EmailTemplates?.customerAccessTemplate || "");
        break; 
      case 'existing_customer_access':
        setEditorContent(EmailTemplates?.existing_customer_accessTemplate || "");
        break;
      case 'building_job':
        setEditorContent(EmailTemplates?.buildJobTemplate || "");
        break;
      case 'active_job':
        setEditorContent(EmailTemplates?.activeJobTemplate || "");
        break;
      case 'payment_received':
        setEditorContent(EmailTemplates?.paymentReceivedTemplate || "");
        break;
      default:
        setEditorContent('');
        break;
    }
  }, [selectedTemplate]);


  const onSubmit = useCallback(
    async (
      values: IEmailTemplateFormData,
      actions: FormikHelpers<IEmailTemplateFormData>
    ) => {

      const CheckType = await client.service(SERVICES.EMAIL_TEMPLATES).find({
        query: {
          type: values.type
        }
      });
      if (CheckType?.data[0]?.type && values.type !== 'none') {
        setTemplate({
          title: CheckType?.data[0]?.title,
          id: CheckType?.data[0]?.id
        })
        setTemplateOpen(true)
      } else {
        const data = handleSubmit(values, actions)
        if (data) {
          navigate(LINKS.TEMPLATES_EMAIL)
        }
      }
    },
    [handleSubmit, navigate]
  )

  const computedInitialValues = useMemo(() => {
    return {
      title: initialValues?.title || '',
      subject: initialValues?.subject || '',
      body: initialValues?.body || editorContent || '',
      type: initialValues?.type || selectedTemplate || '',
    };
  }, [initialValues, editorContent]);

  const selectData = [
    {
      id: 0,
      value: 'none',
      title: TEMPLATES_TYPE['none'],
    },
    {
      id: 1,
      value: 'create_job',
      title: TEMPLATES_TYPE['create_job'],
    },
    {
      id: 2,
      value: 'forget_password',
      title: TEMPLATES_TYPE['forget_password'],
    },
    {
      id: 3,
      value: 'invite_staff',
      title: TEMPLATES_TYPE['invite_staff'],
    },
    {
      id: 3,
      value: 'staff_access',
      title: TEMPLATES_TYPE['staff_access'],
    },
    // {
    //   id: 4,
    //   value: 'onboard_customer',
    //   title: TEMPLATES_TYPE['onboard_customer'],
    // },
    {
      id: 5,
      value: 'customer_access',
      title: TEMPLATES_TYPE['customer_access'],
    },
    {
      id: 6,
      value: 'existing_customer_access',
      title: TEMPLATES_TYPE['existing_customer_access'],
    },
    {
      id: 7,
      value: 'building_job',
      title: TEMPLATES_TYPE['building_job'],
    },
    {
      id: 9,
      value: 'active_job',
      title: TEMPLATES_TYPE['active_job'],
    },
    {
      id: 8,
      value: 'payment_received',
      title: TEMPLATES_TYPE['payment_received'],
    },
  ]


  const UpdateTemplateHandele = async (id: any, values: any) => {
    let existingTempId: any = location.pathname.split("/").pop();
    try {
      if (existingTempId !== "add") {
        await client.service(SERVICES.EMAIL_TEMPLATES).patch(id, {
          type: "none"
        })
        await client.service(SERVICES.EMAIL_TEMPLATES).patch(existingTempId, {
          title: values.title,
          subject: values.subject,
          body: values.body,
          type: values.type
        })
      } else {
        await client.service(SERVICES.EMAIL_TEMPLATES).create({
          title: values.title,
          subject: values.subject,
          body: values.body,
          type: values.type
        })
        await client.service(SERVICES.EMAIL_TEMPLATES).patch(id, {
          type: "none"
        })
      }


      toast.success('Email template updated successfully', {
        position: toast.POSITION.BOTTOM_CENTER,
      })
      setTemplateOpen(false)
      navigate(LINKS.TEMPLATES_EMAIL)
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      })
    }
  }

  const isHTML = (content: string): boolean => {
    const htmlPattern = /<\/?[a-z][\s\S]*>/i;
    return htmlPattern.test(content);
  }

  return (
    <Formik
      initialValues={computedInitialValues}
      enableReinitialize
      validate={(values: IEmailTemplateFormData) => {
        const errors: any = {}
        if (!values.title) {
          errors.title = 'Please add title'
        }

        if (!values.subject) {
          errors.subject = 'Please add subject'
        }

        if (!values.body) {
          errors.body = 'Required'
        }

        if (!values.type) {
          errors.type = 'Please select template type'
        }

        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <div className="email-form-container">
            <div className="left">
              <form onSubmit={handleSubmit}>
                <SelectField
                  label="Select template type"
                  placeholder="Select template type"
                  type="text"
                  id="type"
                  name="type"
                  onChange={(e: any) => {
                    setSelectedTemplate(e.target.value);
                    handleChange(e);
                    
                    console.log("values",values);
                  }}
                  onBlur={handleBlur}
                  value={values.type}
                  errors={errors}
                  selectData={selectData}
                  required
                />
                <TextField
                  label="Template title"
                  id="email-title-input"
                  placeholder="Type email template title..."
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  errors={errors}
                  required
                />
                <TextField
                  label="Subject"
                  id="email-subject-input"
                  placeholder="Type subject..."
                  type="text"
                  name="subject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject}
                  errors={errors}
                  required
                />
                {/* <EmailPreview body={values.body} /> */}
                {/* {isHTML(values.body) ? (
                  <div className="preview-contents" />
                ) : (
                  // <TextArea
                  //   label={`Body`}
                  //   placeholder=""
                  //   name="body"
                  //   onChange={handleChange}
                  //   onBlur={handleBlur}
                  //   value={values.body}
                  //   errors={errors}
                  //   showTempVariables={values.type}
                  //   required
                  // />
                )} */}
                
                <div className="buttons-container right">
                  <Button
                    className='button medium-btn cancel-btn'
                    type="button"
                    disabled={isSubmitting}
                    theme="secondary"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button className='button medium-btn' type="submit" disabled={isSubmitting}>
                    Done
                  </Button>
                </div>
              </form>
            </div>
            <div className="right">
              <EmailPreview body={values.body} />
            </div>
            <ActionModal
              isOpen={templateOpen}
              handleClose={() => setTemplateOpen(false)}
              // title={template?.title}
              subtitle={`This template is already assigned to another email template. By assigning here it will dlink it from ${template?.title} template. Please confirm to proceed.`}
              actionButtons={
                <>
                  <Button
                    theme="secondary"
                    onClick={() => setTemplateOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: '12px' }}
                    onClick={() => UpdateTemplateHandele(template?.id, values)}
                  >
                    Update
                  </Button>
                </>
              }
            />
          </div>
        )
      }}
    </Formik>
  )
}

export default EmailTemplateForm
